
import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
@Component({
  components: {
    Header,
    Footer
  },
})
export default class LandingIndex extends Vue {
  created(): void{
    this.$api.setToken(this.$store.getters.CUSTOMER_TOKEN);
  }
}
