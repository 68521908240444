import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';
import createPersistedState from 'vuex-persistedstate';
import { rejoin, split } from './token-hash';
import SecureLS from "secure-ls";
const ls = new SecureLS({ encodingType: 'aes' });

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    TYSH: null as string|null,
    TYSS: null as string|null,
    TYSL: null as string|null,
    name: null as string|null,
  },
  mutations: {
    setCustomerToken (state, token) {
      [state.TYSH, state.TYSS, state.TYSL] = split(token, 3);
    },
    removeCustomerToken (state) {
      state.TYSH = null;
      state.TYSS = null;
      state.TYSL = null;
    },
    setName (state, name) {
      state.name = name;
    }
  },
  getters: {
    customerUsername: async (state, getters) => {
      if (!getters.CUSTOMER_TOKEN) return 'ورود';
      let tmp = 'حساب کاربری';
      const user = await getters.CUSTOMER;
      if (user.name) tmp = user.name;
      else tmp = user.mobile;
      return tmp;
    },
    CUSTOMER_TOKEN: (state) => {
      return rejoin([state.TYSH as string, state.TYSS as string, state.TYSL as string]);
    },
    isCustomer: (state, getters) => {
      return (getters.CUSTOMER_TOKEN !== null);
    },
    CUSTOMER: async ( state, getters ) => {
      if (!getters.CUSTOMER_TOKEN) return null;
      try {
        const res = await axios
        .get(
          `${process.env.VUE_APP_baseURL}customer/me`,
            {
              headers: {
                Authorization: `Bearer ${getters.CUSTOMER_TOKEN}`
              }
            }
        );
        return res.data;
      } catch {
        return null;  
      }
    },
    name: (state)=> {
      return state.name;
    }
  },
  actions: {
    async auth (context) {
      if (!context.getters.CUSTOMER_TOKEN) return false;
      try {
        const res =
         await axios
        .get(
          `${process.env.VUE_APP_baseURL}customer/me`,
            {
              headers: {
                Authorization: `Bearer ${context.getters.CUSTOMER_TOKEN}`
              }
            }
        );
        return true;
      } catch {
        context.commit('removeCustomerToken')
        return false;  
      }
    },
  },
  modules: {
  },
  plugins: [createPersistedState({
    storage: {
      getItem: (key) => {
        try {
          return ls.get(key)
        } catch {
          ls.remove(key);
          return null
        }
      },
      setItem: (key, value) => ls.set(key, value),
      removeItem: key => ls.remove(key)
    },
    key: '_state',
  })],
})
