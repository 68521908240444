import { RouteConfig } from "vue-router";
// import { isCustomer, auth } from "@/router/auth";

const routes: Array<RouteConfig> = [{
                path: '/giveaway/:give_id',
                component: () => import(/* webpackChunkName: "profile-index" */ '../views/Index.vue'),
                children: [
                  {
                    path: '',
                    name: 'giveaway',
                    component: () => import(/* webpackChunkName: "Giveaway" */ '../views/Giveaway.vue'),
                    // children: [
                    //   {
                    //     path: 'edit',
                    //     name: 'edit-giveaway',
                    //     beforeEnter : async (to, from, next) => {
                    //       await auth();
                    //       if (isCustomer()){
                    //         next();
                    //       } else {
                    //         next('/login?next='+to.fullPath);
                    //       }
                    //     },
                    //     component: () => import(/* webpackChunkName: "edit-giveaway" */ '../views/EditGiveaway.vue'),
                    //   }
                    // ]
                  }
                ]
        },{
          path: '/g/:give_id/rolling',
          alias: '/giveaway/:give_id/rolling',
          name: 'rolling-giveaway',
          component: () => import(/* webpackChunkName: "rolling-giveaway" */ '../views/RollingGiveaway.vue'),
        }
]

export default routes;