
import {Component, Vue} from 'vue-property-decorator';
import OldBrowserContainer from './components/OldBrowserContainer.vue';
import Header from './components/Header.vue';

@Component({
  metaInfo: {
    title: 'جایی برای دورهمی های قرعه‌کشی',
    titleTemplate: '%s | لاکی لایو'
  },
  components: { OldBrowserContainer, Header },
})
export default class App extends Vue {
  isSupported?: boolean;

  created(): void{
    this.$api.setToken(this.$store.getters.CUSTOMER_TOKEN);
  }

  checkFlexGapSupport(): boolean {
            // Use the cached value if it has been defined
    if (this.isSupported !== undefined) {
      return this.isSupported
    }

    // Create a flex container with row-gap set
    const flex = document.createElement('div')
    flex.style.display = 'flex'
    flex.style.flexDirection = 'column'
    flex.style.rowGap = '1px'
    flex.style.position = 'absolute'

    // Create two, elements inside it
    flex.appendChild(document.createElement('div'))
    flex.appendChild(document.createElement('div'))

    // Append to the DOM (needed to obtain scrollHeight)
    document.body.appendChild(flex)

            // Flex container should be 1px high due to the row-gap
    this.isSupported = flex.scrollHeight === 1

            // Remove element from the DOM after you are done with it
    flex.parentNode?.removeChild(flex)

    return this.isSupported;
  }
}
