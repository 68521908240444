
import { Component, Vue } from "vue-property-decorator";
@Component({})
export default class LandingHowItWokrs extends Vue {
  options = [
    {title: 'معیارهای ورودی', text: 
      `اول از همه شرایط شرکت در قرعه‌کشی را مشخص کنید. شما می‌توانید زمان برگزاری را بین 1 روز تا یک ماه آینده انتخاب کنید.`},
    { title: 'انتخاب برندگان', text: 
      `تا روز نهایی می توانید داده های مربروط را ببینید. پس از پایان زمان از بین شرکت کنندگانی که دارای شرایط مشخص شده در مرحله قبل می باشند معین شده. سپس برنده یا برندگان به صورت کاملا شانسی انتخاب می شوند.`},
    { title: 'پخش زنده', text: 
      `از لحظه ایجاد می توانید قرعه کشی را با کاربران خود به اشتراک بگذارید، در لحظه برگزاری قرعه کشی نیز تمام افرادی که مشاهده گر هستند می توانند شاهد برگزاری زنده قرعه کشی باشند.`},
  ]
}
