export const split = (str: string, n: number): Array<string> => {
    const strArray = str.split("");
    const arr = [];
    let j = 0;
    for (let i = 0; i < n; i++) {
      arr[i] = '';
    }
    for (let i = 0; i < strArray.length; i++) {
      const element = strArray[i];
      j>=n?j=0:null;
      arr[j] += element;
      j++;
    }
    return arr;
  }
  
export const rejoin = (arr: Array<string>): string|null => {
    if (arr.length===0||arr[0] === null||arr[arr.length-1] === null)return null;
    let string = "";  
    for (let i = 0; i < arr[0].length; i++) {
      for (let j = 0; j < arr.length; j++) {
        const char = arr[j][i]
        typeof char !=='undefined'?string+=char:null;
      }
    }
    return string;
  }