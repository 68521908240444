import { isCustomer } from "@/router/auth";
import { RouteConfig } from "vue-router";
import Index from "../views/Index.vue";
import Landing from "../views/Landing.vue";

const routes: Array<RouteConfig> = [{
                path: '/',
                component: Index,
                children: [
                        {
                                path: '',
                                name: 'landing-root',
                                component: Landing,
                        }, {
                                path: '/login',
                                name: 'login',
                                beforeEnter : async (to, from, next) => {
                                        const next_to = to.query.next||'profile';
                                        if (isCustomer()){
                                          next('/'+next_to);
                                        } else {
                                          next();
                                        }
                                },
                                component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
                        }, {
                                path: '/privacy-policy',
                                name: 'privacy-policy',
                                component: () => import(/* webpackChunkName: "privacy-policy" */ '../views/PrivacyPolicy.vue'),
                        }
                ]
        },
]

export default routes;