import Vue from 'vue'
import VueMeta from 'vue-meta';
import VueRouter, { RouteConfig } from 'vue-router'
import * as Modules from '../modules';

Vue.use(VueRouter)
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

const routes: Array<RouteConfig> = [
  ...Object.values(Modules).map(module => module.routes).flat(),
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue')

  } ,{
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
