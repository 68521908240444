import Tayeh from 'tayeh.js';

export default class TayehVue {
    version= '0.0.1';
    static install (Vue: any, options: {api_access: string, instance_id: number})  {
        const tayeh = new Tayeh({
            api_access: options.api_access,
            instance_id: options.instance_id,
            name: 'Tayeh Giveaway',
            api_url: process.env.VUE_APP_baseURL,
            auth_url: process.env.VUE_APP_authURL,
            media_url: process.env.VUE_APP_blobURL,
        })
        Object.defineProperty(Vue.prototype, '$tayeh', {
            get () { return tayeh }
          })
    }
}