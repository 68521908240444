
import { Component, Vue } from "vue-property-decorator";
@Component({})
export default class LandinOptions extends Vue {
  options = [
    {icon: require('../assets/wheel.jpg'), title: "انتخاب برنده بصورت تصادفی", text: 
      `برندگان خود را از بین دنبال کنندگان، لایک کنندگان، منشن کنندگان انتخاب کنید.
      هر تعداد برنده که بخواهید میتوانید مشخص کنید.`},
    {icon: require('../assets/megaphone.jpg'), title: "اعلام نتایج", text: 
      `شما قادر خواهید بود، صفحه قرعه کشی خود را با کاربران به اشتراک بگذارید و قابلیت بازید آنلاین برای آنها فعال خواهد بود.`},
    {icon: require('../assets/certificate.jpg'), title: "ضمانتی برای قرعه‌کشی شما", text: 
      `شما می توانید گواهینامه ای ایجاد کنید که نام برندگان و افراد جایگزین را نشان دهد. این گواهی شفافیت قرعه کشی شما را تضمین می کند.`},
    {icon: require('../assets/first-prize.jpg'), title: "یک قرعه کشی با شکوه", text: 
      `مشتری‌ ها را برای یک قرعه کشی پر هیجان دور هم جمع کنید. ما امکان مشاهده زنده قرعه‌کشی را فراهم کرده ایم تا لذت واقعی را احساس کنید.`},
  ]
}
