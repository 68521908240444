import store from '../store';
export async function auth (): Promise<void> {
	await store.dispatch("auth");
}

export function hasToken (): boolean {
	return typeof store.getters.CUSTOMER_TOKEN == 'string';
}

export function isCustomer (): boolean {
	return store.getters.isCustomer;
}