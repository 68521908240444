
import { Component, Vue } from 'vue-property-decorator';
import LandingHeader from '../components/LandingHeader.vue'
import LandingOptions from '../components/LandingOptions.vue'
import SingleOption from '../components/LandingSingleOption.vue'
import LandingGiveaways from '../components/LandingGiveaways.vue'
import How from '../components/LandingHowItWorks.vue'
import { GetLandingPageResponse } from '@/plugins/api';
@Component({
  components: {
    LandingHeader,
    LandingOptions,
    SingleOption,
    How,
    LandingGiveaways
  },
})
export default class LandingPage extends Vue {
    private single_options = [
      {image: require('../assets/excelent_review.jpg'), title: "انتخاب برنده بصورت کاملا اتفاقی", text: 
        `آیا قصد برگزاری یک قرعه‌کشی اینستاگرامی را دارید؟به آسانی میتوانید یک یا چند نفر را بین کامنت گذاران صفحه اینستاگرام خود انتخاب کنید..`, class:'ty-bg-light py-4'},
      {image: require('../assets/landing-cup.jpg'),title: "اعلام نتایج", text: 
        `شما بعد از ایجاد قرعه کشی، این امکان را دارید که تعداد کاربران و شرکت کنندگان در مسابقه را بصورت آنلاین تا زمان برگزاری بررسی کنید. همچنین بعد از اتمام قرعه کشی و اعلام برندگان می توانید به صفحه مسابقه دسترسی داشته باشید و آن را به اشتراک بگذارید.`,
        class: 'reversed'}
    ];
    data: GetLandingPageResponse = {giveaways: []};

    async getGiveaways () {
      try {
        const res = await this.$api.getLandingData();
        this.data = res.data;
      } catch (err){ 
        console.log(err);
      }
    }

    mounted() {
      this.$nextTick(() => {
        this.getGiveaways();
      })
    }
}
