
import { GetCustomerProfileResposne } from "tayeh.js/dist/customer/types";
import { Component, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";
import Logo from '@/components/Logo.vue';
@Component({
  computed: {
    ...mapGetters({isCustomer: 'isCustomer', customer: 'CUSTOMER'})
  },
  components: {
      Logo
  }
})
export default class InstanceHeader extends Vue {
  customer!: GetCustomerProfileResposne;
  isCustomer!: boolean;
  public get username() : string {
    return this.customer?.name||this.customer.username||'حساب کاربری';
  }

  
  public get nonAuthPage() : boolean {    
    return ['Customer-Login', 'Customer-Register', 'admin-login', 'Product', 'CartHistory'].indexOf(this.$route.name as string)===-1
  }
  
  public get tayeh_link() : string {    
    return process.env.VUE_APP_TAYEH
  }
  
  
}
