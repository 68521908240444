import { RouteConfig } from "vue-router";
import { isCustomer, auth } from "@/router/auth";

const routes: Array<RouteConfig> = [{
                path: '/profile',
                name: 'my-profile',
                beforeEnter : async (to, from, next) => {
                        await auth();
                        if (isCustomer()){
                          next();
                        } else {
                          next('/login?next='+to.fullPath);
                        }
                },
                component: () => import(/* webpackChunkName: "profile-index" */ '../views/Index.vue'),
                children: [
                { path: '',
                component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
                },
                  {
                    path: 'new-giveaway',
                    name: 'new-giveaway',
                    component: () => import(/* webpackChunkName: "new-giveaway" */ '../views/NewGiveaway.vue'),
                  },{
                    path: 'latest-giveaway',
                    name: 'latest-giveaway',
                    component: () => import(/* webpackChunkName: "latest-giveaway" */ '../views/LatestGiveaway.vue'),
                  },{
                    path: 'active-giveaways',
                    name: 'active-giveaways',
                    component: () => import(/* webpackChunkName: "active-giveaways" */ '../views/ActiveGiveaways.vue'),
                  },{
                    path: 'archive-giveaways',
                    name: 'archive-giveaways',
                    component: () => import(/* webpackChunkName: "archive-giveaways" */ '../views/ArchiveGiveaways.vue'),
                  }
                ]
        }
]

export default routes;