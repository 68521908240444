import { UserInstaAccount } from "@/types/gateway";
import Axios, { AxiosInstance, AxiosResponse } from "axios";


export class API {
    constructor() {
        this.token = `Bearer ${process.env.VUE_APP_ACCESS}`;
        this.axios = Axios.create({
            baseURL: process.env.VUE_APP_giveURL,
            timeout: 5000,
        })
    }

    axios: AxiosInstance;
    token: string;

    setToken (token: string|null): void{
        if (!token) return;
        this.token = 'Bearer '+token;
        return;
    }

    getProfile (): Promise<AxiosResponse<GetUserProfileResponse>> {
        if (!this.token) throw new Error('Forbidden 403');
        return this.axios.get('/user/profile', {
            headers: {
                Authorization: this.token
            }
        })
    }

    getGiveaway (id: string): Promise<AxiosResponse<GetGiveawayResponse>> {
        return this.axios.get('/giveaway/'+id, {
            headers: {
                Authorization: this.token
            }
        })
    }

    getMyLatestGiveaway (): Promise<AxiosResponse<GetUserGiveawayResponse>> {
        if (!this.token) throw new Error('Forbidden 403');
        return this.axios.get('/user/latest-giveaway', {
            headers: {
                Authorization: this.token
            }
        })
    }

    getMyGiveaways (): Promise<AxiosResponse<GetUserGiveawayResponse[]>> {
        if (!this.token) throw new Error('Forbidden 403');
        return this.axios.get('/user/giveaways', {
            headers: {
                Authorization: this.token
            }
        })
    }

    getMyArchive (): Promise<AxiosResponse<GetUserGiveawayResponse[]>> {
        if (!this.token) throw new Error('Forbidden 403');
        return this.axios.get('/user/archive', {
            headers: {
                Authorization: this.token
            }
        })
    }

    createNewGiveaway (data: CreateGiveawayData): Promise<AxiosResponse<void>> {
        if (!this.token) throw new Error('Forbidden 403');
        return this.axios.put('/user/giveaway', data, {
            headers: {
                Authorization: this.token
            }
        })
    }

    checkPayment (data: CheckInvoiceRequest): Promise<AxiosResponse<void>> {
        if (!this.token) throw new Error('Forbidden 403');
        return this.axios.post('/giveaway/check-invoice', data, {
            headers: {
                Authorization: this.token
            }
        })
    }

    get404Data (): Promise<AxiosResponse<Get404PageResponse>> {
        return this.axios.get('/user/404-page', {
            headers: {
                Authorization: this.token
            }
        })
    }

    getLandingData (): Promise<AxiosResponse<GetLandingPageResponse>> {
        return this.axios.get('/user/landing-page', {
            headers: {
                Authorization: this.token
            }
        })
    }
}
export default class TayehVue {
    version= '0.0.1';
    static install (Vue: any)  {
        const api = new API();
        Object.defineProperty(Vue.prototype, '$api', {
            get () { return api }
          })
    }
}

export type GetUserProfileResponse = {
    can_create_giveaway: boolean;
    active_count: number;
    done_count: number;
}

export type GetUserGiveawayResponse = {
    model_id: string|null;
    give_id: string|null;
    creator_id: string;
    prize: string;
    description: string;
    auto_describe?: string;
    type: string;
    post?: Post;
    status: GiveawayStatus;
    post_url: string;
    shortcode: string;
    total_enrolled: number;
    total_verified: number;
    chances: number;
    ends_at: number;
    winners: Winner[];
    crowled_at: number;
    createdAt: number;
}

export type Winner = {
    instagram_username: string;
    avatar: string;
    mobile: string;
    selected_at: number;
}

export type Post = {
    id: string;
    image: string;
    caption: string;
    likes_count: number;
    comments_count: number;
    view_count: number;
    plays_count: number;
    follower_count: number;
    is_video: boolean;
}

export enum GiveawayStatus {
    payment_required = 0,
    active = 1,
    stoped = 2,
    before_rolling = 3,
    done = 4,
    prize_paid = 5,
    spam = 6
}

type CreateGiveawayData = {
    invoice_id: string;
    prize: string;
    media_id: string; //^(?:.*\/p\/)([\d\w\-_]+)
    description?: string;
    chances: number;
    days_count: number;
    min_tags_count: number;
    should_follow: boolean;
    should_like: boolean;
    should_comment: boolean;
}

class CheckInvoiceRequest {
    give_id!: string;
}

type GiveawayViewer = {
    is_owner: boolean;
    is_signed: boolean;
  }
  
export type GetGiveawayResponse = {
    model_id: string;
    give_id: string;
    creator_id: string;
    viewer: GiveawayViewer;
    prize: string;
    description: string;
    auto_describe: string;
    post?: Post;
    status: GiveawayStatus;
    post_url: string;
    shortcode: string;
    total_enrolled: number;
    total_verified: number;
    chances: number;
    ends_at: number;
    remaining: number;
    roller_users: UserInstaAccount[];
    winners: Winner[];
    crawled_at: number;
    createdAt: number;
}

type GiveawayFor404Page = {
  model_id: string;
  creator_id: string;
  prize: string;
  auto_describe: string;
  post?: Post;
  status: GiveawayStatus;
  post_url: string;
  ends_at: number;
}

export type GiveawayForLandingPage = {
    model_id: string;
    creator_id: string;
    prize: string;
    auto_describe: string;
    chances: string;
    date: string;
    post?: Post;
    status: GiveawayStatus;
    post_url: string;
    ends_at: number;
  }

type LatestWinner = {
  avatar: string;
  username: string;
  position: number;
}

export type Get404PageResponse = {
  new_giveaways: GiveawayFor404Page[];
  active_giveaways: GiveawayFor404Page[];
  winners: LatestWinner[];
}

export type GetLandingPageResponse = {
    giveaways: GiveawayForLandingPage[];
  }
