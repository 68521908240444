<template>
	<div>
		<img class="image" v-show="showImage" @load="handleLoad" @error="handleError" :style="{width, height, borderRadius: radius}"
			:src="`https://cors.bimion.ir/?url=${src}`" />
		<div v-if="showLoading">
			<slot name="placeholder">
				<img :style="{width, height, borderRadius: radius}"
					src="@/assets/images/picture.png" />
			</slot>
		</div>
		<div v-if="showError">
			<slot name="error">
				<img :style="{width, height, borderRadius: radius}"
					src="@/assets/images/picture.png" />
			</slot>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'ImageView',
		props: {
			src: String,
			width: {
				type: [String, Number],
				default: '100%'
			},
			height: {
				type: [String, Number],
				default: 'auto'
			},
			radius: {
				type: [String, Number],
				default: 0
			}
		},
		data() {
			return {
				loading: true,
				error: false,
			}
		},
		computed: {
			showImage() {
				return !this.loading && this.src && !this.error
			},
			showLoading() {
				return this.loading
			},
			showError() {
				return !this.loading && this.error
			}
		},
		methods: {
			handleLoad() {
				this.loading = false
			},
			handleError() {
				this.loading = false;
				this.error = true
			}
		}
	}
</script>