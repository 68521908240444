import Vue from 'vue';
import App from './App.vue';
import TayehVue from './plugins/tayeh-vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import 'tayeh-ui/dist/index.min.css';
import TayehUi from 'tayeh-ui/dist/index.es.min.js';
import '@/assets/styles/shared.scss';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import API from './plugins/api';
import io from 'socket.io-client';
import VueSocketIOExt from 'vue-socket.io-extended';
import Particles from "particles.vue";
Vue.config.productionTip = false
Vue.use(TayehVue, {api_access: process.env.VUE_APP_ACCESS, instance_id: Number(process.env.VUE_APP_INSTANCE)});
Vue.use(API);
Vue.use(TayehUi)
Vue.use(Toast, {});
Vue.use(Particles);
// Vue.use(new VueSocketIO({
//   debug: true,
//   connection: process.env.VUE_APP_SOCKET,
//   vuex: {
//       store,
//       actionPrefix: 'SOCKET_',
//       mutationPrefix: 'SOCKET_'
//   },
// }))
const socket = io(process.env.VUE_APP_SOCKET);
Vue.use(VueSocketIOExt, socket);

function format (valString: string) {
	const val = valString.toString();
	return val.replace(/\B(?=(?:\d{3})+(?!\d))/g, ',')
}
Vue.filter('currency', function (value: any) {
  return (value || value === 0)
    ? format(''+value)
    : ''
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
